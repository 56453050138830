// MacTrack au colors

// primary

$light-primary: #333333;
$light-primary-contrast: #ffffff;

$dark-primary: #000000;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #e51e1b;
$light-accent-contrast: #ffffff;

$dark-accent: #e51e1b;
$dark-accent-contrast: #ffffff;

// common

$page-background: #3333332f;
